
import { Component } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

const maintenance = namespace('maintenance');

@Component({
  components: { VWrapper },
})
export default class Maintenance extends mixins(Notification) {
  @maintenance.State('active') public maintenance!: boolean;
  @maintenance.Action('enable') public enableMaintenance!: any;
  @maintenance.Action('disable') public disableMaintenance!: any;
}
